export function formatDateToMMYYYY(dt) {
  const date = new Date(dt);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${year}`;
}

export function toISOString(yyyymmdd) {
  yyyymmdd = String(yyyymmdd);
  const year = yyyymmdd.slice(0, 4);
  const month = yyyymmdd.slice(4, 6) - 1;
  const day = yyyymmdd.slice(6, 8);

  const date = new Date(year, month, day);
  return date.toISOString();
}
