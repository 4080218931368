import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCropSimple, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, getQuickViewDiscovery } from 'services/analysis';
import { parseSearchParams } from 'helpers/location';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import PortfolioActions from 'page-partials/portfolio-analysis/BuildPortfolio/PortfolioActions';
import { useArrayState } from 'components/CustomHooks';
import CustomDataModal from './CustomDataModal';

const HundredInfoTooltip = ({ id } = {}) => {
  return (
    <>
      <span id={id} className="pl-1">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
      <UncontrolledTooltip delay={0} target={id}>
        The sum of the asset allocations must be 100 %
      </UncontrolledTooltip>
    </>
  );
};

const PortfolioLabel = styled.span`
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-bottom: 0 !important;
  text-transform: uppercase !important;
  border: 0 !important;
  color: black !important;
  word-break: break-all;
  text-transform: none !important;
`;

const CheckSign = styled.span`
  position: absolute;
  left: 0;
  top: 15px;
`;

const BottomTD = styled.td`
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  padding-left: 0.8rem !important;
  font-weight: 600 !important;
`;

const selectOptions = {
  valueContainer: () => ({
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
  }),
  menu: () => ({
    zIndex: 200,
    position: 'absolute',
    backgroundColor: '#fff',
  }),
};

const frequencies = optionizeAll(['Annual', 'Semi', 'Quarterly', 'Monthly'], {
  labelFormatter: _.startCase,
});

const getSum = (nums) => {
  return parseFloat(_.sum(_.compact(nums))).toFixed(2);
};

const getRest = (nums) => {
  const rest = 100 - _.sum(_.compact(nums));
  return rest < 0 ? 0 : rest;
};

const isHundred = (nums) => {
  const sum = _.sum(_.compact(nums));
  return Math.round(sum * 100) / 100 === 100;
};

const trimData = (assets, databases, weights1, weights2, weights3) => {
  const trimmedAssets = [];
  const trimmedDatabases = [];
  const trimmedWeights1 = [];
  const trimmedWeights2 = [];
  const trimmedWeights3 = [];

  _.each(assets, (asset, i) => {
    if (!asset) return;

    if (weights1[i] || weights2[i] || weights3[i]) {
      trimmedAssets.push(asset);
      trimmedDatabases.push(databases[i]);
      trimmedWeights1.push(weights1[i]);
      trimmedWeights2.push(weights2[i]);
      trimmedWeights3.push(weights3[i]);
    }
  });

  return {
    assets: trimmedAssets,
    databases: trimmedDatabases,
    weights1: trimmedWeights1,
    weights2: trimmedWeights2,
    weights3: trimmedWeights3,
  };
};

export const DEFAULT_PORTFOLIO_1 = 'Portfolio #1';
export const DEFAULT_PORTFOLIO_2 = 'Portfolio #2';
export const DEFAULT_PORTFOLIO_3 = 'Portfolio #3';

const defaultCurrencyOptions = [
  { value: 'Default', label: 'No' },
  { value: 'CAD', label: 'CAD (Convert USD)' },
  { value: 'USD', label: 'USD (Convert CAD)' },
];

const tabOptions = [
  { value: 'analyze', label: 'Analyze', permission: 'pa-build-analyze' },
  { value: 'advanced', label: 'Advanced', permission: 'pa-build-advanced' },
];

const defaultNulls = [null, null, null, null, null];
const defaultZeros = [0, 0, 0, 0, 0];

function BuildPortfolio({ confirm, session }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showCustomDataModal, setShowCustomDataModal] = useState(true);
  const [single, setSingle] = useState(false);
  const [resetTime, setResetTime] = useState(0);
  const [port1Name, setPort1Name] = useState(DEFAULT_PORTFOLIO_1);
  const [port2Name, setPort2Name] = useState(DEFAULT_PORTFOLIO_2);
  const [port3Name, setPort3Name] = useState(DEFAULT_PORTFOLIO_3);
  const [assetsMeta, setAssetsMeta] = useState({});
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [assets, setAssets] = useState(defaultNulls.concat());
  const [databases, setDatabases] = useState(defaultNulls.concat());
  const [portEnabled2, setPortEnabled2] = useState(true);
  const [portEnabled3, setPortEnabled3] = useState(true);
  const [weights1, setWeights1] = useState(defaultZeros.concat());
  const [weights2, setWeights2] = useState(defaultZeros.concat());
  const [weights3, setWeights3] = useState(defaultZeros.concat());
  const [dataLoading, setDataLoading] = useState(false);
  const [metaLoading, setMetaLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [benchmarks, setBenchmarks] = useState([]);
  const [benchmark, setBenchmark] = useState(null);
  const [geographys, setGeographys] = useState([]);
  const [geography, setGeography] = useState(null);
  const [riskbuckets, setRiskbuckets] = useState([]);
  const [riskbucket, setRiskbucket] = useState(null);
  const [correlations, setCorrelations] = useState([]);
  const [correlation, setCorrelation] = useState(null);
  const [significances, setSignificances] = useState([]);
  const [significance, setSignificance] = useState(null);
  const [sdate, setSdate] = useState(null);
  const [edate, setEdate] = useState(null);
  const [rebalanceFreq, setRebalanceFreq] = useState(frequencies[0]);
  const [accrualFreq, setAccrualFreq] = useState(frequencies[0]);
  const [deductionFreq, setDeductionFreq] = useState(frequencies[0]);
  const [advisorFees, setAdvisorFees] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabOptions[0]);
  const [holdings, setHoldings] = useState(false);
  const [currencyOptions, setCurrencyOptions, selectedCurrencyOption, setSelectedCurrencyOption] = useArrayState({
    initialItems: defaultCurrencyOptions,
  });
  const [error, setError] = useState('');
  const maxItems = session?.permissions['pb-max-items'] ?? 1;

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setSingle(search.get('single') === 'true');
    setShowCustomDataModal(true);
  }, [location]);

  useEffect(() => {
    setPortEnabled2(!single);
    setPortEnabled3(!single);
  }, [single]);

  const handlePortfolio1LoadSelected = useCallback(
    (portfolio) => {
      _.each(weights1, (_, i) => (weights1[i] = 0));

      _.each(portfolio.data, (row) => {
        const assetInd = assets.findIndex((asset) => asset === row.asset);
        if (assetInd > -1 && databases[assetInd] === row.database) {
          weights1[assetInd] = row.weight;
        } else {
          assets.push(row.asset);
          databases.push(row.database);
          weights1.push(row.weight);
          weights2.push(0);
          weights3.push(0);
        }
      });

      const {
        assets: _assets,
        databases: _databases,
        weights1: _weights1,
        weights2: _weights2,
        weights3: _weights3,
      } = trimData(assets, databases, weights1, weights2, weights3);

      setAssets(_assets);
      setDatabases(_databases);
      setWeights1(_weights1);
      setWeights2(_weights2);
      setWeights3(_weights3);
      setPort1Name(portfolio.name);
    },
    [assets, databases, weights1, weights2, weights3],
  );

  const handlePortfolio2LoadSelected = useCallback(
    (portfolio) => {
      _.each(weights2, (_, i) => (weights2[i] = 0));

      _.each(portfolio.data, (row) => {
        const assetInd = assets.findIndex((asset) => asset === row.asset);
        if (assetInd > -1 && databases[assetInd] === row.database) {
          weights2[assetInd] = row.weight;
        } else {
          assets.push(row.asset);
          databases.push(row.database);
          weights1.push(0);
          weights2.push(row.weight);
          weights3.push(0);
        }
      });

      const {
        assets: _assets,
        databases: _databases,
        weights1: _weights1,
        weights2: _weights2,
        weights3: _weights3,
      } = trimData(assets, databases, weights1, weights2, weights3);

      setAssets(_assets);
      setDatabases(_databases);
      setWeights1(_weights1);
      setWeights2(_weights2);
      setWeights3(_weights3);
      setPort2Name(portfolio.name);
    },
    [assets, databases, weights1, weights2, weights3],
  );

  const handlePortfolio3LoadSelected = useCallback(
    (portfolio) => {
      _.each(weights3, (_, i) => (weights3[i] = 0));

      _.each(portfolio.data, (row) => {
        const assetInd = assets.findIndex((asset) => asset === row.asset);
        if (assetInd > -1 && databases[assetInd] === row.database) {
          weights3[assetInd] = row.weight;
        } else {
          assets.push(row.asset);
          databases.push(row.database);
          weights1.push(0);
          weights2.push(0);
          weights3.push(row.weight);
        }
      });

      const {
        assets: _assets,
        databases: _databases,
        weights1: _weights1,
        weights2: _weights2,
        weights3: _weights3,
      } = trimData(assets, databases, weights1, weights2, weights3);

      setAssets(_assets);
      setDatabases(_databases);
      setWeights1(_weights1);
      setWeights2(_weights2);
      setWeights3(_weights3);
      setPort3Name(portfolio.name);
    },
    [assets, databases, weights1, weights2, weights3],
  );

  const handlePortfolio1SaveSelected = (portfolio) => {
    setPort1Name(portfolio.name);
  };

  const handlePortfolio2SaveSelected = (portfolio) => {
    setPort2Name(portfolio.name);
  };

  const handlePortfolio3SaveSelected = (portfolio) => {
    setPort3Name(portfolio.name);
  };

  const handlePortfolio1ClearSelected = () => {
    setWeights1(weights1.map(() => 0));
    setPort1Name(DEFAULT_PORTFOLIO_1);
  };

  const handlePortfolio2ClearSelected = () => {
    setWeights2(weights2.map(() => 0));
    setPort2Name(DEFAULT_PORTFOLIO_2);
  };

  const handlePortfolio3ClearSelected = () => {
    setWeights3(weights3.map(() => 0));
    setPort3Name(DEFAULT_PORTFOLIO_3);
  };

  const fetchData = async () => {
    setDataLoading(true);
    const [data, err] = await getQuickViewDiscovery();
    if (!data) {
      setAssetsMeta({});
      setDatabaseOptions([]);
    } else {
      const keys = _.keys(data);
      setAssetsMeta(data);
      setDatabaseOptions(optionizeAll(keys));
    }

    setDataLoading(false);
  };

  const fetchMeta = async () => {
    setMetaLoading(true);
    const [meta, err] = await getDiscovery();
    if (!meta) {
      setMeta({});
      setBenchmarks([]);
      setBenchmark(null);
      setGeographys([]);
      setGeography(null);
      setRiskbuckets([]);
      setRiskbucket(null);
      setCorrelations([]);
      setCorrelation(null);
      setSignificances([]);
      setSignificance(null);
    } else {
      setMeta(meta);
      const benchmarks = meta.benchmark_choice.map(optionizeValue);
      const geographys = meta.geography_choice.map(optionizeValue);
      const riskbuckets = meta.risk_budget_choice.map(optionizeValue);
      const correlations = meta.correlation_choice.map(optionizeValue);
      const significances = meta.significance_choice.map((level) => ({
        value: level,
        label: confidenceLevelToInterval(level),
      }));

      setBenchmarks(benchmarks);
      setBenchmark(benchmarks[0]);
      setGeographys(geographys);
      setGeography(geographys[0]);
      setRiskbuckets(riskbuckets);
      setRiskbucket(riskbuckets[0]);
      setCorrelations(correlations);
      setCorrelation(correlations[0]);
      setSignificances(significances);
      setSignificance(significances[0]);
    }

    setMetaLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchMeta();
  }, []);

  useEffect(() => {
    const params = parseSearchParams(location.search, [
      { key: 'assets', multi: true },
      { key: 'databases', multi: true },
      { key: 'weights1', multi: true },
      { key: 'weights2', multi: true },
      { key: 'weights3', multi: true },
      'benchmark',
      'geography',
      'riskbucket',
      'correlation',
      'significance',
      'sdate',
      'edate',
      'rebalanceFreq',
      'accrualFreq',
      'deductionFreq',
      'advisorFees',
      'port1Name',
      'port2Name',
      'port3Name',
      'converter',
    ]);

    if (params.assets.length > 0) {
      setAssets(params.assets);
      setDatabases(params.databases);
      setWeights1(params.weights1.map((v) => (v ? parseFloat(v) : 0)));

      if (params.weights2.length > 0) {
        setWeights2(params.weights2.map((v) => (v ? parseFloat(v) : 0)));
      } else {
        setWeights2([]);
        setPortEnabled2(false);
      }

      if (params.weights3.length > 0) {
        setWeights3(params.weights3.map((v) => (v ? parseFloat(v) : 0)));
      } else {
        setWeights3([]);
        setPortEnabled3(false);
      }

      setBenchmark(params.benchmark || null);
      setGeography(params.geography || null);
      setRiskbucket(params.riskbucket || null);
      setCorrelation(params.correlation || null);
      setSignificance(params.significance || null);
      setSdate(params.sdate || null);
      setEdate(params.edate || null);
      setRebalanceFreq(
        params.rebalanceFreq ? frequencies.find((v) => v.value === params.rebalanceFreq) : frequencies[0],
      );
      setAccrualFreq(params.accrualFreq ? frequencies.find((v) => v.value === params.accrualFreq) : frequencies[0]);
      setDeductionFreq(
        params.deductionFreq ? frequencies.find((v) => v.value === params.deductionFreq) : frequencies[0],
      );
      setSelectedCurrencyOption(defaultCurrencyOptions.find((v) => v.value === (params.converter || 'Default')));
      setAdvisorFees(params.advisorFees || 0);
      setPort1Name(params.port1Name);
      setPort2Name(params.port2Name);
      setPort3Name(params.port3Name);
    }
  }, [location]);

  const allowed = useMemo(() => {
    const compactAssets = _.compact(assets);
    if (compactAssets.length === 0) return false;

    for (let x = 0; x < assets.length; x++) {
      const asset = assets[x];
      const database = databases[x];
      if (asset && !database) return false;
      if (!asset && database) return false;
    }

    if (!isHundred(weights1)) return false;
    if (portEnabled2 && !isHundred(weights2)) return false;
    if (portEnabled3 && !isHundred(weights3)) return false;

    return true;
  }, [assets, databases, weights1, weights2, weights3, portEnabled2, portEnabled3]);

  const handleAnalyze = () => {
    if (!allowed) return;

    const mdiff = monthDiff(sdate, edate);
    if (mdiff !== Number.MIN_VALUE) {
      if (mdiff < 0) {
        setError('Start date cannot be after end date.');
        return;
      }

      if (mdiff < 12) {
        setError('The analysis time interval should include at least 12 months.');
        return;
      }
    }

    const params = {
      single,
      assets,
      databases,
      port1Name,
      port2Name,
      port3Name,
      weights1: weights1.map((v) => v || 0),
      weights2: portEnabled2 ? _.map(weights2, (v) => v || 0) : [],
      weights3: portEnabled3 ? _.map(weights3, (v) => v || 0) : [],
      benchmark: getValue(benchmark),
      geography: getValue(geography),
      riskbucket: getValue(riskbucket),
      correlation: getValue(correlation),
      significance: getValue(significance),
      sdate: getYearMonth(sdate),
      edate: '',
      rebalanceFreq: getValue(rebalanceFreq),
      accrualFreq: getValue(accrualFreq),
      deductionFreq: getValue(deductionFreq),
      advisorFees: advisorFees,
      holdings,
      converter: selectedCurrencyOption.value,
    };

    navigate(
      {
        pathname: `/portfolio-analysis-${single ? 'single' : 'multiple'}-${selectedTab.value}`,
        search: `?${createSearchParams(params)}`,
      },
      { replace: false },
    );
  };

  const getWeightedArray = () => {
    const selected = assets.filter(Boolean).length;
    const eql = Math.round((100 / selected) * 100) / 100;

    let lastIndex = 0;
    const ret = assets.map((asset, index) => {
      if (asset) {
        lastIndex = index;
        return eql;
      }
      return 0;
    });

    const total = _.sum(ret);
    if (total < 100) {
      ret[lastIndex] += 100 - total;
    } else if (total > 100) {
      ret[lastIndex] -= total - 100;
    }

    ret[lastIndex] = Number(ret[lastIndex].toFixed(2));

    return ret;
  };

  if (metaLoading || _.isEmpty(assetsMeta)) {
    return <FixedCenterLoader />;
  }

  return (
    <>
      <div className="tw-flex">
        <Button
          className=""
          color="default"
          onClick={() => {
            confirm({
              content: 'Are you sure to reset all portfolios?',
              callback: (result) => {
                if (result != 'confirmed') return;

                navigate(
                  {
                    pathname: `/build-portfolio`,
                    search: null,
                  },
                  { replace: false },
                );

                setPort1Name(DEFAULT_PORTFOLIO_1);
                setPort2Name(DEFAULT_PORTFOLIO_2);
                setPort3Name(DEFAULT_PORTFOLIO_3);
                setDatabases(defaultNulls.concat());
                setAssets(defaultNulls.concat());
                setPortEnabled2(true);
                setPortEnabled3(true);
                setWeights1(defaultZeros.concat());
                setWeights2(defaultZeros.concat());
                setWeights3(defaultZeros.concat());
                setResetTime(new Date().getTime());
              },
            });
          }}
        >
          Reset
        </Button>
        <div className="tw-w-full">
          <PageTabs options={tabOptions} session={session} onTabSelect={setSelectedTab}>
            <TabPane tabId="analyze" role="tabpanel"></TabPane>
            <TabPane tabId="advanced" role="tabpanel"></TabPane>
          </PageTabs>
        </div>
      </div>

      <Card className={single ? 'tw-max-w-3xl tw-mx-auto' : ''}>
        <CardBody>
          <Table hover striped bordered className="tw-table-auto">
            <thead>
              <tr>
                <th>SELECT ASSETS</th>
                <th>
                  <div className="tw-flex tw-justify-between">
                    <div>
                      <PortfolioLabel className="">
                        <div>{port1Name}</div>
                        <Button
                          className="btn-round tw-py-1 tw-px-2 tw-text-xs"
                          size="sm"
                          color="primary"
                          onClick={() => {
                            setWeights1(getWeightedArray());
                          }}
                        >
                          Equal Weight
                        </Button>
                      </PortfolioLabel>
                    </div>
                    <PortfolioActions
                      name="1"
                      confirm={confirm}
                      assets={assets}
                      databases={databases}
                      weights={weights1}
                      onSaveSelected={handlePortfolio1SaveSelected}
                      onLoadSelected={handlePortfolio1LoadSelected}
                      onClearSelected={handlePortfolio1ClearSelected}
                      key={resetTime}
                    />
                  </div>
                </th>
                {!single && (
                  <>
                    <th>
                      <div className="tw-flex tw-justify-between">
                        <div>
                          <FormGroup check>
                            <Label check>
                              <Input
                                checked={portEnabled2}
                                type="checkbox"
                                onChange={(e) => setPortEnabled2(e.target.checked)}
                              />
                              <CheckSign className="form-check-sign" />
                              <PortfolioLabel className="noselect">
                                <div>{port2Name}</div>
                                <Button
                                  className="btn-round tw-py-1 tw-px-2 tw-text-xs"
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    setWeights2(getWeightedArray());
                                  }}
                                >
                                  Equal Weight
                                </Button>
                              </PortfolioLabel>
                            </Label>
                          </FormGroup>
                        </div>

                        <PortfolioActions
                          name="2"
                          confirm={confirm}
                          assets={assets}
                          databases={databases}
                          weights={weights2}
                          onSaveSelected={handlePortfolio2SaveSelected}
                          onLoadSelected={handlePortfolio2LoadSelected}
                          onClearSelected={handlePortfolio2ClearSelected}
                          key={resetTime}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="tw-flex tw-justify-between">
                        <div>
                          <FormGroup check>
                            <Label check>
                              <Input
                                checked={portEnabled3}
                                type="checkbox"
                                onChange={(e) => setPortEnabled3(e.target.checked)}
                              />
                              <CheckSign className="form-check-sign" />
                              <PortfolioLabel className="noselect">
                                <div>{port3Name}</div>
                                <Button
                                  className="btn-round tw-py-1 tw-px-2 tw-text-xs"
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    setWeights3(getWeightedArray());
                                  }}
                                >
                                  Equal Weight
                                </Button>
                              </PortfolioLabel>
                            </Label>
                          </FormGroup>
                        </div>

                        <PortfolioActions
                          name="3"
                          confirm={confirm}
                          assets={assets}
                          databases={databases}
                          weights={weights3}
                          onSaveSelected={handlePortfolio3SaveSelected}
                          onLoadSelected={handlePortfolio3LoadSelected}
                          onClearSelected={handlePortfolio3ClearSelected}
                          key={resetTime}
                        />
                      </div>
                    </th>
                  </>
                )}

                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {assets.map((asset, i) => {
                const databaseAssetOptions = optionizeAll((databases[i] && assetsMeta[databases[i]]) || []);

                return (
                  <tr key={`${asset}-${i}`}>
                    <td width={single ? '40%' : '24%'}>
                      <Select
                        className="react-select tw-min-w-[370px]"
                        name="databases"
                        placeholder="Select Database..."
                        value={databaseOptions.find((option) => option.value === databases[i])}
                        onChange={(option) => {
                          databases[i] = option.value;
                          assets[i] = null;
                          setDatabases(databases.concat());
                          setAssets(assets.concat());
                        }}
                        options={databaseOptions}
                        styles={selectOptions}
                      />
                      <Select
                        className="react-select tw-min-w-[370px]"
                        name="assets"
                        placeholder="Select Asset..."
                        value={databaseAssetOptions.find((option) => option.value === asset)}
                        onChange={(option) => {
                          assets[i] = option.value;
                          setAssets(assets.concat());
                        }}
                        // exclude the asset already taken
                        options={databaseAssetOptions.filter((option) => {
                          if (option.value === option) return true;
                          if (assets.includes(option.value)) return false;
                          return true;
                        })}
                        styles={selectOptions}
                      />
                    </td>
                    <td width={single ? '60%' : '24%'}>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          min={0}
                          max={100}
                          value={weights1[i]}
                          onChange={(event) => {
                            weights1[i] = parseFloat(parseFloat(event.target.value).toFixed(2));
                            setWeights1(weights1.concat());
                          }}
                          onWheel={(event) => {
                            event.target?.blur();
                            event.currentTarget?.blur();
                          }}
                        />
                      </FormGroup>
                    </td>
                    {!single && (
                      <>
                        <td width="24%">
                          <FormGroup className="mb-0">
                            <Input
                              type="number"
                              min={0}
                              max={100}
                              value={weights2[i]}
                              onChange={(event) => {
                                weights2[i] = parseFloat(parseFloat(event.target.value).toFixed(2));
                                setWeights2(weights2.concat());
                              }}
                              onWheel={(event) => {
                                event.target?.blur();
                                event.currentTarget?.blur();
                              }}
                            />
                          </FormGroup>
                        </td>
                        <td width="24%">
                          <FormGroup className="mb-0">
                            <Input
                              type="number"
                              min={0}
                              max={100}
                              value={weights3[i]}
                              onChange={(event) => {
                                weights3[i] = parseFloat(parseFloat(event.target.value).toFixed(2));
                                setWeights3(weights3.concat());
                              }}
                              onWheel={(event) => {
                                event.target?.blur();
                                event.currentTarget?.blur();
                              }}
                            />
                          </FormGroup>
                        </td>
                      </>
                    )}

                    {/* <td className="text-center">
                      {assets.length > 1 && (
                        <Button
                          className="my-0"
                          color="warning"
                          type="button"
                          size="md"
                          onClick={() => {
                            const exceptThis = (v, j) => i !== j;
                            setAssets(assets.filter(exceptThis));
                            setWeights1(weights1.filter(exceptThis));
                            setWeights2(weights2.filter(exceptThis));
                            setWeights3(weights3.filter(exceptThis));
                          }}
                        >
                          <FontAwesomeIcon icon={faClose} />
                        </Button>
                      )}
                    </td> */}
                  </tr>
                );
              })}
              <tr key="sum">
                <BottomTD width={single ? '40%' : '24%'}></BottomTD>
                <BottomTD
                  width={single ? '40%' : '24%'}
                  className={classnames({
                    'bg-danger': !isHundred(weights1),
                  })}
                >
                  <span className="tw-break-keep">{getSum(weights1)}%</span>{' '}
                  {!isHundred(weights1) && <HundredInfoTooltip id="hundred-portfolio1" />}
                </BottomTD>
                {!single && (
                  <>
                    {portEnabled2 ? (
                      <BottomTD
                        width="24%"
                        className={classnames({
                          'bg-danger': !isHundred(weights2),
                        })}
                      >
                        <span className="tw-break-keep">{getSum(weights2)}%</span>{' '}
                        {!isHundred(weights2) && <HundredInfoTooltip id="hundred-portfolio2" />}
                      </BottomTD>
                    ) : (
                      <BottomTD width="24%"></BottomTD>
                    )}

                    {portEnabled3 ? (
                      <BottomTD
                        width="24%"
                        className={classnames({
                          'bg-danger': !isHundred(weights3),
                        })}
                      >
                        <span className="tw-break-keep">{getSum(weights3)}%</span>{' '}
                        {!isHundred(weights3) && <HundredInfoTooltip id="hundred-portfolio3" />}
                      </BottomTD>
                    ) : (
                      <BottomTD width="24%"></BottomTD>
                    )}
                  </>
                )}
              </tr>
            </tbody>
          </Table>

          {assets.length < maxItems && (
            <>
              <Button
                className="btn-round"
                color="info"
                onClick={() => {
                  setDatabases(databases.concat(...defaultNulls.concat()));
                  setAssets(assets.concat(...defaultNulls.concat()));
                  setWeights1(weights1.concat(...defaultZeros.concat()));
                  setWeights2(weights2.concat(...defaultZeros.concat()));
                  setWeights3(weights3.concat(...defaultZeros.concat()));
                }}
              >
                Add Assets
              </Button>
              <Button
                className="btn-round"
                color="danger"
                disabled={databases.length <= 5}
                onClick={() => {
                  let num = 5;
                  if (databases.length < 10) {
                    num = databases.length - 5;
                  }

                  setDatabases(databases.slice(0, databases.length - num));
                  setAssets(assets.slice(0, assets.length - num));
                  setWeights1(weights1.slice(0, weights1.length - num));
                  setWeights2(weights2.slice(0, weights2.length - num));
                  setWeights3(weights3.slice(0, weights3.length - num));
                }}
              >
                Remove Assets
              </Button>
            </>
          )}
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="mb-0 text-center">
            Modeling Assumptions
          </CardTitle>
        </CardHeader>
        <CardBody className="mt-3">
          <Form className="form-horizontal">
            <Row>
              <Col sm="3" className="text-right">
                <Label>Convert Currency</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  {selectedCurrencyOption && (
                    <Select
                      className="react-select"
                      name="selectedCurrencyOption"
                      value={selectedCurrencyOption}
                      onChange={setSelectedCurrencyOption}
                      options={currencyOptions}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="text-right">
                <Label>Rebalance Freq</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  {rebalanceFreq && (
                    <Select
                      className="react-select"
                      name="rebalanceFreq"
                      value={rebalanceFreq}
                      onChange={setRebalanceFreq}
                      options={frequencies}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="text-right">
                <Label>Benchmark Selection</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  {benchmark && (
                    <Select
                      className="react-select"
                      name="benchmark"
                      value={benchmark}
                      onChange={setBenchmark}
                      options={benchmarks}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
              <Col sm="3" className="text-right">
                <Label>Geography (CAPM &amp; FFM)</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  {geography && (
                    <Select
                      className="react-select"
                      name="geography"
                      value={geography}
                      onChange={setGeography}
                      options={geographys}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="text-right">
                <Label>Factor Groups</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  {riskbucket && (
                    <Select
                      className="react-select"
                      name="riskbucket"
                      value={riskbucket}
                      onChange={setRiskbucket}
                      options={riskbuckets}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="text-right">
                <Label>Correlation Group</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  {correlation && (
                    <Select
                      className="react-select"
                      name="correlation"
                      value={correlation}
                      onChange={setCorrelation}
                      options={correlations}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="text-right">
                <Label>Confidence Interval</Label>
              </Col>
              <Col sm="7">
                {significance && (
                  <FormGroup>
                    <Select
                      className="react-select"
                      name="significance"
                      value={significance}
                      onChange={setSignificance}
                      options={significances}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row> */}
            <Row>
              <Col sm="3" className="text-right">
                <Label>Start Date (Optional)</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  <ReactDatetime
                    initialValue={sdate}
                    dateFormat="YYYY-MM"
                    onChange={setSdate}
                    inputProps={{
                      className: 'form-control',
                      placeholder: 'Start Date',
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* <Row>
              <Col sm="3" className="text-right">
                <Label>End Date (Optional)</Label>
              </Col>
              <Col sm="7">
                <FormGroup>
                  <ReactDatetime
                    initialValue={edate}
                    dateFormat="YYYY-MM"
                    onChange={setEdate}
                    inputProps={{
                      className: 'form-control',
                      placeholder: 'End Date',
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                  />
                </FormGroup>
              </Col>
            </Row> */}
            {error && (
              <Row>
                <Col sm="3"></Col>
                <Col sm="7">
                  <div className="tw-text-[#ef8157]">{error}</div>
                </Col>
              </Row>
            )}
            {single && session.permissions['pa-holdings-view'] && (
              <Row>
                <Col sm="3"></Col>
                <Col sm="7">
                  <FormGroup check>
                    <Label check>
                      <Input checked={holdings} type="checkbox" onChange={(e) => setHoldings(e.target.checked)} />
                      <span className="form-check-sign" />
                      View Holdings
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Form>
        </CardBody>
      </Card>

      {selectedTab.value === 'advanced' && (
        <Card>
          <CardHeader>
            <CardTitle tag="h4" className="mb-0 text-center">
              Advisor Fees
            </CardTitle>
          </CardHeader>
          <CardBody className="mt-3">
            <Form className="form-horizontal">
              <Row>
                <Col sm="3" className="text-right">
                  <Label>Advisor Fees (Optional)</Label>
                </Col>
                <Col sm="7">
                  <FormGroup>
                    <Input
                      type="number"
                      min={0}
                      max={100000}
                      value={advisorFees}
                      onChange={(event) => {
                        setAdvisorFees(parseFloat(event.target.value));
                      }}
                      onWheel={(event) => {
                        event.target?.blur();
                        event.currentTarget?.blur();
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3" className="text-right">
                  <Label>Accrual Freq</Label>
                </Col>
                <Col sm="7">
                  <FormGroup>
                    {accrualFreq && (
                      <Select
                        className="react-select"
                        name="accrualFreq"
                        value={accrualFreq}
                        onChange={setAccrualFreq}
                        options={frequencies}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3" className="text-right">
                  <Label>Deduction Freq</Label>
                </Col>
                <Col sm="7">
                  <FormGroup>
                    {deductionFreq && (
                      <Select
                        className="react-select"
                        name="deductionFreq"
                        value={deductionFreq}
                        onChange={setDeductionFreq}
                        options={frequencies}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      )}

      <div className="text-center">
        <Button className="btn-round" color="info" disabled={!allowed} onClick={handleAnalyze}>
          Analyze
        </Button>
      </div>
      <CustomDataModal
        isOpen={showCustomDataModal}
        isMulti={!single}
        onClose={(data) => {
          if (data) {
            const assets = [];
            const databases = [];
            const weights1 = [];
            const weights2 = [];
            const weights3 = [];

            for (let x = 0; x < data.length; x++) {
              const item = data[x];
              assets.push(item.asset);
              databases.push(item.database);
              weights1.push(item.weight1);
              weights2.push(item.weight2);
              weights3.push(item.weight3);
            }

            setAssets(assets);
            setDatabases(databases);
            setWeights1(weights1);
            setWeights2(weights2);
            setWeights3(weights3);
          }

          setShowCustomDataModal(false);
        }}
      />
    </>
  );
}

export default BuildPortfolio;
