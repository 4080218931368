import _ from 'lodash';
import { createUserSavedCharacteristic } from './user-saved-characteristics';
import { createUserSavedReturn } from './user-saved-returns';

export async function createMultipleUserSaved({
  names,
  symbols,
  types,
  jurisdictions,
  currencies,
  categories,
  subCategories,
  timestamps,
  returnss,
}) {
  let invalidSymbols = [];

  const charsResult = await Promise.allSettled(
    symbols.map((symbol, i) =>
      createUserSavedCharacteristic(names[i], {
        symbol,
        type: types[i],
        currency: currencies[i],
        jurisdiction: jurisdictions[i],
        category: categories[i],
        subCategory: subCategories[i],
      }),
    ),
  );
  charsResult.forEach((v, i) => v.status === 'rejected' && invalidSymbols.push(symbols[i]));

  const retResult = await Promise.allSettled(
    symbols.map((symbol, i) => createUserSavedReturn(names[i], { timestamps, returns: returnss[i] })),
  );
  retResult.forEach((v, i) => v.status === 'rejected' && invalidSymbols.push(symbols[i]));

  invalidSymbols = _.uniq(invalidSymbols);

  if (invalidSymbols.length > 0) {
    throw Error(`Invalid Symbol Entry ${invalidSymbols.join(', ')}`);
  }

  return true;
}
