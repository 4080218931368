import React, { useState, useContext, useMemo } from 'react';
import _ from 'lodash';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTrashAlt, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { createClientPortfolio, listClientPortfolios, deleteClientPortfolio } from 'services/client-portfolio';
import PortfolioSaveModal from './PortfolioSaveModal';
import PortfolioDeleteConfirmModal from './PortfolioDeleteConfirmModal';
import { AdminContext } from 'layouts/Admin';

function PortfolioActions({
  name = '',
  confirm,
  assets = [],
  databases = [],
  weights = [],
  onSaveSelected = _.noop,
  onLoadSelected = _.noop,
  onClearSelected = _.noop,
}) {
  const context = useContext(AdminContext);
  const [portfolios, setPortfolios] = useState([]);
  const [portfolio, setPortfolio] = useState(null);
  const [portfolioIdToDelete, setPortfolioIdToDelete] = useState(null);
  const [showSaveDropdown, setShowSaveDropdown] = useState(false);
  const [showLoadDropdown, setShowLoadDropdown] = useState(false);
  const [showPortfolioSaveModal, setShowPortfolioSaveModal] = useState(false);
  const [showPortfolioDeleteConfirmModal, setShowPortfolioDeleteConfirmModal] = useState(false);
  const maxItems = context?.session?.permissions['pa-max-saved'] ?? 1;

  const onSaveDropdownToggle = async () => {
    if (showSaveDropdown) {
      setShowSaveDropdown(false);
    } else {
      const [data, err] = await listClientPortfolios();
      setPortfolios(data);
      setShowSaveDropdown(true);
    }

    setShowLoadDropdown(false);
  };

  const onLoadDropdownToggle = async () => {
    if (showLoadDropdown) {
      setShowLoadDropdown(false);
    } else {
      const [data, err] = await listClientPortfolios();
      setPortfolios(_.orderBy(data, ['name', ['asc']]));
      setShowLoadDropdown(true);
    }

    setShowSaveDropdown(false);
  };

  const [data, valid, invalidReason] = useMemo(() => {
    const result = [];
    let sum = 0;
    _.each(weights, (weight, i) => {
      if (isNaN(weight)) return;
      if (assets[i] && weight > 0) {
        result.push({ asset: assets[i], database: databases[i], weight });
        sum += weight;
      }
    });

    const sum100 = sum === 100;
    const includeSavedPortfolios = result.findIndex((v) => v.database === 'Saved Portfolios') > -1;
    const valid = sum100 && !includeSavedPortfolios;

    let invalidReason = '';
    if (!valid) {
      invalidReason =
        result.length === 0
          ? 'Please select at least one asset.'
          : includeSavedPortfolios
          ? 'Saving a "Saved Portfolio" is not allowed.'
          : 'Total weights must add up to 100%.';
    }

    return [result, valid, invalidReason];
  }, [assets, databases, weights]);

  return (
    <div className="">
      <Dropdown className="tw-mb-1" isOpen={showLoadDropdown} toggle={onLoadDropdownToggle}>
        <DropdownToggle nav className="tw-text-xs tw-p-0">
          Select
        </DropdownToggle>
        <DropdownMenu right className="dropdown-success tw-max-h-[300px] tw-overflow-y-scroll">
          {portfolios.map((portfolio) => (
            <DropdownItem
              onClick={() => {
                setPortfolio(portfolio);
                onLoadSelected(portfolio);
              }}
            >
              <div className="tw-flex tw-justify-between">
                <div className="tw-pr-2">
                  <span className="">{portfolio.name}</span>
                </div>
                <div
                  className="tw-h-full hover:tw-text-red-700 hover:tw-text-bold"
                  onClick={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowPortfolioDeleteConfirmModal(true);
                    setPortfolioIdToDelete(portfolio.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <Dropdown className="tw-mb-1" isOpen={showSaveDropdown} toggle={valid ? onSaveDropdownToggle : _.noop}>
        <DropdownToggle
          nav
          className={classnames('tw-text-xs tw-p-0 tw-mr-3', {
            'tw-text-slate-300 hover:tw-text-slate-300 tw-cursor-not-allowed': !valid,
          })}
        >
          <span id={`invalid-${name}`}>Save</span>
          {invalidReason && (
            <UncontrolledTooltip delay={0} target={`invalid-${name}`}>
              {invalidReason}
            </UncontrolledTooltip>
          )}
        </DropdownToggle>
        <DropdownMenu right className="dropdown-success">
          {portfolios.length < maxItems ? (
            <DropdownItem onClick={() => setShowPortfolioSaveModal(true)}>
              <FontAwesomeIcon icon={faCloudArrowUp} className="tw-mr-1" />
              Save as New
            </DropdownItem>
          ) : (
            !portfolio && (
              <DropdownItem>
                <span className="tw-italic tw-text-sm">
                  You have reached the maximum limit of {maxItems} spots currently available.
                </span>
              </DropdownItem>
            )
          )}
          {portfolio && (
            <DropdownItem
              onClick={() => {
                if (portfolio) {
                  confirm({
                    content: `This will overwrite the existing portfolio "${portfolio.name}". Are you sure you would like to continue with this overwrite?`,
                    callback: (result) => {
                      if (result != 'confirmed') return;
                      createClientPortfolio(portfolio.name, data);
                    },
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={faCloudArrowUp} className="tw-mr-1" />
              Save Current
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      <Dropdown
        className=""
        isOpen={false}
        toggle={() => {
          confirm({
            content: 'Are you sure to clear all weights?',
            callback: (result) => {
              if (result != 'confirmed') return;
              setPortfolio(null);
              onClearSelected();
            },
          });
        }}
      >
        <DropdownToggle nav className="tw-text-xs tw-p-0">
          Clear
        </DropdownToggle>
      </Dropdown>
      <PortfolioSaveModal
        isOpen={showPortfolioSaveModal}
        onClose={(portfolio) => {
          if (portfolio) {
            onSaveSelected(portfolio);
            setPortfolio(portfolio);
          }
          setShowPortfolioSaveModal(false);
        }}
        data={data}
        portfolios={portfolios}
        confirm={confirm}
      />
      <PortfolioDeleteConfirmModal
        isOpen={showPortfolioDeleteConfirmModal}
        onClose={async (result) => {
          setShowPortfolioDeleteConfirmModal(false);

          if (result !== 'confirmed') return;
          if (!portfolioIdToDelete) return;

          await deleteClientPortfolio(portfolioIdToDelete);
          setShowLoadDropdown(false);
        }}
      />
    </div>
  );
}

export default PortfolioActions;
