import React, { useState, useEffect, useMemo } from 'react';
import { read, utils } from 'xlsx';
import { Button } from 'reactstrap';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { toISOString } from 'helpers/date';
import bulkUploadTemplateFile from 'assets/files/bulk-upload-template.xlsx';
import { createMultipleUserSaved as _createMultipleUserSaved } from 'services/user-saved';

function ImportData({ notify }) {
  const fileRef = React.useRef();

  const { mutateAsync: createMultipleUserSaved, isPending: isCreateMultipleUserSaved } = useMutation({
    mutationFn: _createMultipleUserSaved,
    onSuccess: () => {
      notify.success({ message: <div>Uploaded successfully</div> });
    },
    onError: (error) => {
      notify.danger({ message: <div>{String(error)}</div> });
    },
  });

  const handleChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes

    if (file.size > maxSize) {
      alert('File size exceeds 20MB. Please choose a smaller file.');
      fileRef.current.value = null;
      return;
    }

    const cont = await file.arrayBuffer();

    const wb = read(cont);
    const ws = wb.Sheets[wb.SheetNames[0]];
    const jsa = utils.sheet_to_json(ws, { header: 1 });

    let names = [];
    let symbols = [];
    let types = [];
    let jurisdictions = [];
    let currencies = [];
    let categories = [];
    let subCategories = [];

    for (let x = 0; x <= 6; x++) {
      const row = jsa[x];
      const items = [].concat(row.slice(1));

      switch (row[0]) {
        case 'Name':
          names = items;
          break;
        case 'Symbol':
          symbols = items;
          break;
        case 'Type':
          types = items;
          break;
        case 'Jurisdiction':
          jurisdictions = items;
          break;
        case 'Currency':
          currencies = items;
          break;
        case 'Category':
          categories = items;
          break;
        case 'Sub-Category':
          subCategories = items;
          break;
      }
    }

    const timestamps = [];
    const returnss = [];

    const firstDataRow = jsa[7];
    for (let x = 0; x < firstDataRow.length; x++) {
      if (!firstDataRow[x]) break;

      for (let y = 7; y < jsa.length; y++) {
        const cell = jsa[y][x];

        if (!cell) break;

        if (x === 0) {
          timestamps.push(cell);
          continue;
        }

        if (!returnss[x - 1]) {
          returnss[x - 1] = [];
        }

        returnss[x - 1].push(cell);
      }
    }

    fileRef.current.value = null;

    await createMultipleUserSaved({
      names,
      symbols,
      types,
      jurisdictions,
      currencies,
      categories,
      subCategories,
      timestamps: timestamps.map(toISOString),
      returnss,
    });
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  return (
    <div className="tw-w-full tw-text-center tw-mt-10">
      <div className="fileinput tw-text-center tw-p-0 tw-m-0">
        <input type="file" accept=".xlsx,.csv" onChange={handleChange} ref={fileRef} />
        <Button className="btn" color="info" onClick={() => handleClick()}>
          <i className="fa fa-upload" aria-hidden="true"></i>
          Upload My Data
        </Button>
        <a
          className="hover:tw-no-underline tw-ml-1 tw-font-semibold tw-px-[16px] tw-py-[9px] tw-border tw-border-solid tw-border-[#191970] tw-text-[#191970] tw-bg-white hover:tw-text-[#191970] focus:tw-text-[#191970]"
          target="_blank"
          href={bulkUploadTemplateFile}
        >
          <i className="fa fa-download" aria-hidden="true"></i>
          Download Template
        </a>
      </div>
    </div>
  );
}

export default ImportData;
