import React, { useState } from 'react';
import { Modal, Button } from 'reactstrap';
import { useMutation } from '@tanstack/react-query';
import classnames from 'classnames';
import { searchTickers } from 'services/analysis';

function SpreadsheetData({ data, searched, isMulti }) {
  if (!data || data.length === 0 || !searched) return;

  return (
    <div className="tw-overflow-x-auto">
      <table className="tw-min-w-full tw-border tw-border-gray-300 tw-shadow-md tw-rounded-md">
        <thead>
          <tr className="tw-bg-gray-200 tw-text-gray-700 tw-text-center">
            <th className="tw-border tw-px-4 tw-py-2">Symbol</th>
            <th className="tw-border tw-px-4 tw-py-2">Weight1</th>
            {isMulti && (
              <>
                <th className="tw-border tw-px-4 tw-py-2">Weight2</th>
                <th className="tw-border tw-px-4 tw-py-2">Weight3</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            const ticker = row[0];
            const notFound = searched.list_not_found.includes(ticker);

            return (
              <tr
                key={rowIndex}
                className={classnames('tw-hover:bg-gray-100', {
                  'tw-text-red-500': notFound,
                })}
              >
                {row.map((cell, colIndex) => (
                  <td key={colIndex} className="tw-border tw-px-4 tw-py-2 tw-text-center">
                    {cell}
                    {notFound && colIndex === 0 && <span className="tw-text-red-500">*</span>}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {searched.list_not_found.length > 0 && (
        <div class="tw-flex tw-items-center tw-bg-yellow-200 tw-text-yellow-800 tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm tw-mb-4">
          <span className="tw-text-red-500">We couldn’t find these symbols with *.</span>
        </div>
      )}
    </div>
  );
}

function CustomDataModal({ isOpen, isMulti = true, onClose }) {
  const [data, setData] = useState(null);
  const [searched, setSearched] = useState(null);

  const { mutateAsync: _searchTickers, isPending: isSearchingTickers } = useMutation({
    mutationFn: searchTickers,
  });

  const handlePaste = async (event) => {
    const pastedData = event.clipboardData.getData('text');

    const numberOfColumns = isMulti ? 4 : 2;
    const parsedData = pastedData
      .split('\n')
      .map((v) => v.split('\t').slice(0, numberOfColumns))
      .filter(
        (row) => row.some((cell) => cell.trim() !== '') && row.slice(1).every((cell, index) => !isNaN(Number(cell))),
      )
      .map((row) => {
        for (let i = 1; i < row.length; i++) {
          if (!isNaN(Number(row[i]))) {
            row[i] = Number(row[i]);
          }
        }
        return row;
      });

    const tickets = parsedData.map((row) => row[0]);
    const searched = await _searchTickers(tickets);

    setSearched(searched);
    setData(parsedData);
  };

  const handleSubmit = async () => {
    let validData = data.filter((row) => !searched.list_not_found.includes(row[0]));
    validData = validData.map((v) => {
      const meta = searched.clean_output[v[0]];
      return {
        database: meta[0],
        asset: meta[1],
        weight1: v[1],
        weight2: v[2] ?? 0,
        weight3: v[3] ?? 0,
      };
    });

    setData(null);
    setSearched(null);
    await onClose(validData);
  };

  return (
    <Modal isOpen={isOpen} size="lg" className="tw-mt-2 tw-pt-0">
      <div className="modal-header">
        <h5 className="modal-title" id="myModalLabel">
          Copy and Paste Spreadsheet Data
        </h5>
      </div>
      <div className="modal-body">
        <p>Use the text box below to paste data from the spreadsheet, or skip it and fill out the form manually.</p>
        <textarea
          class="tw-w-full tw-h-20 tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-resize-none"
          placeholder="Please paste the copied data here..."
          onPaste={handlePaste}
          value=""
        ></textarea>
        <SpreadsheetData data={data} searched={searched} isMulti={isMulti} />
      </div>
      <div className="modal-footer tw-justify-between tw-px-10">
        <div></div>
        <div>
          <Button className="btn-round" color="info" data-dismiss="modal" type="button" onClick={() => onClose()}>
            Skip
          </Button>
          {searched && (
            <Button className="btn-round" color="success" data-dismiss="modal" type="button" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CustomDataModal;
