import { instance } from './axios';
import _ from 'lodash';

const headers = { 'x-app-cache': 'true' };

export async function getUserSavedReturn(name) {
  const result = await instance.post(`/user-saved-returns/retrieve`, { name }).then((res) => res.data);

  return result;
}

export async function listUserSavedReturns() {
  const result = await instance.get('/user-saved-returns').then((res) => res.data);
  return result;
}

export async function createUserSavedReturn(name, data = {}) {
  const { timestamps, returns } = data;
  const result = await instance
    .post('/user-saved-returns', { name, data: JSON.stringify({ timestamps, returns }) })
    .then((res) => res.data);

  return result;
}

export async function deleteUserSavedReturn(name, data = {}) {
  const result = await instance.delete(`/user-saved-returns/${name}`).then((res) => res.data);

  return result;
}
