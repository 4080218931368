import { instance } from './axios';
import _ from 'lodash';

const headers = { 'x-app-cache': 'true' };

export async function getUserSavedCharacteristic(name) {
  const result = await instance.post(`/user-saved-characteristics/retrieve`, { name }).then((res) => res.data);

  return result;
}

export async function listUserSavedCharacteristics() {
  const result = await instance.get('/user-saved-characteristics').then((res) => res.result);
  return result;
}

export async function createUserSavedCharacteristic(name, data = {}) {
  const { symbol, type, currency, jurisdiction, category, subCategory } = data;
  const result = await instance
    .post('/user-saved-characteristics', {
      name,
      data: JSON.stringify({
        Symbol: symbol,
        Type: type,
        Currency: currency,
        Jurisdiction: jurisdiction,
        Category: category,
        'Sub-Category': subCategory,
      }),
    })
    .then((res) => res.data);

  return result;
}

export async function deleteUserSavedCharacteristic(name, data = {}) {
  const result = await instance.delete(`/user-saved-characteristics/${name}`).then((res) => res.data);

  return result;
}
